
const section1 = /*html*/`
<!-- home -->
<section id="section1">
<div id="section_inview_1" class="scroll_detector"></div>

<div class="window-div draggable">
    <div class="window-div-header drag-handle"></div>

    <div class="window-content">
    <span>❯ function</span>
    <h1>software engineer</h1>
    <span>❯ name</span>
    <h2>Kevin Bloch</h2>
    <span>❯ area</span>
    <p>Zürich, Switzerland</p> 
    <!-- <span>❯ available</span>
    <p>freelance & contracts</p> -->
    <p id="blink"/>
    </div>
</div>

<!-- <div class="window-div draggable" id="secondary">
    <div class="window-div-header drag-handle"></div>
    
    <div class="window-content">
    <span>❯ current contract</span>
    <p>textshuttle - AI Translation</p>
    <span>❯ working on</span>
    <p>CI/CD workflows for AI models</p>  
    </div>
</div> -->

</section>
`


const section2 = /*html*/`
<!-- about -->
<section id="section2">
  <div id="section_inview_2"  class="scroll_detector"></div>

  <div class="window-div draggable">
    <div class="window-div-header drag-handle"></div>
    <div class="window-content">

      <!-- <span>❯ techstack</span>
      <h2>fullstack</h2> -->

      <span>❯ frontend</span>
      <li>HTML CSS JavaScript</li> 
      <li>TypeScript React next.js</li>
      <li>Node.js esbuild</li>


      <p/>
    </div>
</div>

<div class="window-div draggable" id="secondary">
  <div class="window-div-header drag-handle"></div>
  
  <div class="window-content">

  <span>❯ backend</span>
  <li>Python FastAPI Flask celery</li>
  <li>Ruby, RoR</li>
  <li>MySQL git Linux</li>
  <li>Docker Regex Redis</li>

  <!-- <span>❯ previous lives</span>
  <p>copywriter & UX expert</p>
  <p><a href="kevinbloch.ch">=> kevinbloch.ch</a></p>
  <p/> -->

  </div>
</div>
</section>
`

const section3 = /*html*/`
<!-- contact -->
<section id="section3">
  <div id="section_inview_3" class="scroll_detector"></div>

  <div class="window-div draggable">
    <div class="window-div-header drag-handle"></div>
    
    <div class="window-content">

    <span>❯ contact</span>
    <p> <a href="mailto:dev@kblo.ch">dev@kblo.ch</a></p>
    <p/>

    <!-- 

    <p> github</p>
    <p> linkedin</p>
    <p> twitter</p> -->
    </div>
  </div>
  
  <div class="window-div draggable" id="secondary">
    <div class="window-div-header drag-handle"></div>
    
    <div class="window-content">

    <span>❯ the 3D thingy</span>
    <!-- <h2>About</h2> -->
    <p>󰆧 destroy it</p>

    <span>❯ then</span>
    <!-- <h2>About</h2> -->
    <p>scroll </p>
    
    <p/>

    </div>
  </div>


</section>
`


export {section1, section2, section3}