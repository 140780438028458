import { gradient } from './1_gradient.js'
import { threeScene } from './2_cube.ts';
import { colorThemes } from './constants.ts';


export function setColorTheme(theme: string, update_gradient: boolean = true) {

    document.body.setAttribute('activeColorTheme', theme)

    for (const color_var in colorThemes[theme]) {
        // change global css vars
        if (color_var.includes('--')) {
            document.documentElement.style.setProperty(color_var, colorThemes[theme][color_var]);
        }

        // cube color must be set via material
        if (color_var === 'cubeColor') {
            var threeShape = threeScene.getObjectByName( "points" );
            threeShape.material.color.setHex(colorThemes[theme][color_var]);
        }

        // change  gradient colors
        if (color_var.includes('--g-gradient-color-') && update_gradient) {
            // color_var is something like --g-gradient-color-1
            let last_character: string = color_var.at(-1)
            let index: number = Number(last_character)-1
            console.log(color_var, index)
            gradient.updateColor (index, colorThemes[theme][color_var]);
        }
    }
}

// set colormode based on browser settings
function matchBrowserTheme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setColorTheme("dark", false);
    }
}
matchBrowserTheme()