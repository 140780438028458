
import { navigation } from "./nav";
import { section1, section2, section3 } from "./sections";

export const main = /*html*/`
<main id="main">
  <div class="grid"></div>
  <div id="content">
    ${section1}
    ${section2}
    ${section3}
  </div>

  ${navigation}

  <div class="desktop" dropzone="true"></div>
</main>
  `;