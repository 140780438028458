import * as THREE from 'three'
// @ts-ignore  
import ScrollWatcher from 'scroll-watcher'

import animate_console from './console'
import { destabilize } from './destabilize'
import { colorThemes } from './constants.ts';

let camera, scene, renderer
let points: THREE.Points
let sizeChange: string = 'shrink'

const possiblePointStates = ['entry', 'normalize', 'destabilizable']
var activePointStates : string[] = []

const mouse = new THREE.Vector2()
let destable, requestID


const SHAPES : { [name: string]: THREE.BufferGeometry } = {
  sphere :new THREE.SphereGeometry(2),
  cube :new THREE.BoxGeometry(2, 2, 2, 10, 10, 10),
  pyramid :new THREE.ConeGeometry(2,2,4, 30),
  donut :new THREE.TorusGeometry( 2, .5, 30, 100 ),
  plane :new THREE.PlaneGeometry( 2, 2, 40, 40 ),
}

let pointsObject: THREE.Points = initAnimation("donut")
export const threeScene = scene
animate()


export function initAnimation(shape: string) {
  // remove existing points object if there is one => required if we want to change shapes
  if (scene !== undefined) {
    var existingPointsObject = scene.getObjectByName( "points" );
    if (existingPointsObject !== undefined) {
      scene.remove(existingPointsObject)
      stopAnimation()
      animate()
    }
  }
  
  pointsObject = init(shape)
  pointsObject.name = "points";  
  return pointsObject
}




function init(shapeString: string) {
  if (scene === undefined) { scene = new THREE.Scene(); scene.name = "the scene" }
  
  camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
  camera.position.z = 5

  renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
  renderer.setSize(window.innerWidth, window.innerHeight)
  renderer.domElement.id = 'cube'
  document.body.appendChild(renderer.domElement)

  // Points
  const shape = SHAPES[shapeString]
  function r_geometry () { return new THREE.EdgesGeometry(shape, 360) }
  const geometry = r_geometry()
  const material = new THREE.PointsMaterial({
    size: 0.02,
    color: colorThemes[document.body.getAttribute('activeColorTheme')]['cubeColor'] //0x000000,
  })
  points = new THREE.Points(geometry, material)
  points.scale.x = 0
  points.scale.y = 0
  points.scale.z = 0
  // points.matrixAutoUpdate = false
  scene.add(points)

  // on scroll
  scroll_watch()

  // evenlistenders
  window.addEventListener('resize', onWindowResize, false)
  document.addEventListener('mousemove', onDocumentMouseMove, false)

  // destabilize module
  destable = destabilize()
  destable.init(points, mouse, camera, r_geometry(), material)

  return points // return to export & change color
}

function animate () {
  // cube does not exist at beginning because boot
  if (document.getElementById('cube') != null) {
    if (activePointStates.includes('entry')) { entry() }
    if (activePointStates.includes('normalize')) { normalize() }
    if (activePointStates.includes('destabilizable')) { destable.animate() }

    // keep points moving after destabilization even if in another state
    if (!activePointStates.includes('destabilizable')) { destable.random_cloud() }

    points.geometry.attributes.position.needsUpdate = true

    renderer.render(scene, camera)
  }
  requestID = requestAnimationFrame(animate)
}

function stopAnimation() {
  cancelAnimationFrame( requestID );
}


function shrink (speed : number = 0.01, min_size : number = 0) {
  if (points.scale.x > min_size) {
    points.scale.x -= speed
    points.scale.y -= speed
    points.scale.z -= speed
  }
}

function grow (speed: number  = 0.01, max_size: number = 3) {
  if (points.scale.x < max_size) {
    points.scale.x += speed
    points.scale.y += speed
    points.scale.z += speed
  }
}

function entry () {
  // shrink first
  // if small enough, grow
  if (points.scale.x < 3) { sizeChange = 'grow' }
  if (sizeChange === 'grow') { grow() }

  // if large enough, start rotating
  if (points.scale.x >= 1) {
    normal_rotate(points)
  }
}

function normalize (target_size: number = 1) {
  if (points.scale.x < 1) { sizeChange = 'grow' }
  if (sizeChange === 'grow') { grow(0.02, target_size) }
  if (points.scale.x > 1) { sizeChange = 'shrink' }
  if (sizeChange === 'shrink') { shrink(0.02, target_size) }
  normal_rotate(points)
}

function scroll_watch () {
  const scroll = new ScrollWatcher()

  possiblePointStates.forEach((x, i) => {
    const index = String(i + 1)
    const sectionstr = 'section_inview_'.concat(index)
    const navstr : string = 'nav'.concat(index)
    const sectstr = 'section'.concat(index)

    const navigation_button = document.getElementById(navstr)
    const pageSection = document.getElementById(sectstr)

    scroll
      .watch(document.getElementById(sectionstr))
      .on('enter', function () {
        // cube animation
        activePointStates = [x]

        // selects nav element
        navigation_button?.classList.add('selected')
        
        // marks section div as scrolled
        pageSection?.classList.add('scrolled')
        animate_console()
      })
      .on('exit', function () {
        // deselects nav element
        navigation_button?.classList.remove('selected')
        pageSection?.classList.remove('scrolled')
      })
  })
}

function onWindowResize () {
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()
  renderer.setSize(window.innerWidth, window.innerHeight)
}

function onDocumentMouseMove (e: MouseEvent) {
  mouse.x = (e.clientX / window.innerWidth) * 2 - 1
  mouse.y = -(e.clientY / window.innerHeight) * 2 + 1
}

function normal_rotate (o: THREE.Points, rotation: number = 0.001) {
  o.rotation.x += rotation
  o.rotation.y += rotation
  o.rotation.z += rotation
}
