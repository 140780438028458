import { main } from "./main"
import { boot } from "./boot"

export const body = /*html*/`
<body activeColorTheme="light">
    

${boot}

${main}

<div class="fade_wrap">
  <canvas id="gradient-canvas" data-transition-in />
</div>
      
</body>
`