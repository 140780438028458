export const boot = /*html*/`
<div id="boot">
  <p>looking up domain <span>IP address</span></p>
  <p>initiating <span>TCP connection</span></p>
  <p>waking <span>server</span></p>
  <p>server farm requests <span>energy</span></p>
  <p>German <span>nuclear reactors</span> seem to be inoperative</p>
  <p>searching alternative <span>energy sources</span></p>
  <p><span>wind energy</span> insufficient</p>
  <p><span>cloud</span> blocking solar panels</p>
  <p>searching alternative to alternative<span>energy sources</span></p>
  <p>harnessing <span>hot air</span> emited at climate conferences</p>
  <p>energy <span>found</span></p>
  <p><span>server</span> ready</p>
  <p>getting <span>HTTP request</span></p>
  <p>processing <span>HTTP request</span></p>
  <p>sending <span>HTTP response</span></p>
  <p>loading <span>html css JavaScript</span></p>
  <p>rendering...</p>
  <p>hello world</p>
  <p></p>
  <p>Please update your browser to view this page.</p>
</div>
`
