export const colorThemes : { [name: string]: {} } = {
    'dark': {
        '--bg-color': 'black',
        '--fg-color': 'white',
        '--accent-color': 'white',
        '--nav-color': 'white',
        '--link-color': 'blue',

        '--g-gradient-color-1': '#000000',
        '--g-gradient-color-2': '#282828',
        '--g-gradient-color-3': '#3a405a',
        '--g-gradient-color-4': '#1E1D26',

        'cubeColor': 0xffffff,
    },
    'light': {
        '--bg-color': 'white',
        '--fg-color': 'rgb(76, 76, 76)',
        '--accent-color': 'black',
        '--nav-color': 'black',
        '--link-color': 'blue',


        '--g-gradient-color-1': '#b8b8b8',
        '--g-gradient-color-2': '#d5d5d5',
        '--g-gradient-color-3': '#e2e2e2',
        '--g-gradient-color-4': '#ffffff',

        'cubeColor': 0x000000,
    },
    'blue': {
        '--bg-color': 'blue',
        '--fg-color': 'white',
        '--accent-color': 'white',
        '--nav-color': '#ffffff',
        '--link-color': 'black',

        '--g-gradient-color-1': '#5c99b8',
        '--g-gradient-color-2': '#4c447a',
        '--g-gradient-color-3': '#56e6ff',
        '--g-gradient-color-4': '#0d2157',

        'cubeColor': 0x81e6ff,
    }
}