import * as THREE from 'three'

function destabilize () {
  let chaos_countdown: number = 0
  let raycaster: THREE.Raycaster
  let particlesMove: { [name: number]: number } = {}

  let clone: THREE.Points
  let verticesArr: Float32Array
  let urGeo: Float32Array

  let mainShape: THREE.Points
  let mouse: THREE.Vector2
  let camera: THREE.PerspectiveCamera
  const particleSpeed: number = 0.01

  // publicApi functions
  // publicApi function 1: initialize by remembering original geometry of object
  function init (
    object_init: THREE.Points, 
    mouse_init: THREE.Vector2, 
    camera_init: THREE.PerspectiveCamera, 
    geometry: THREE.EdgesGeometry, 
    material: THREE.PointsMaterial
    ) {
    raycaster = new THREE.Raycaster()
    raycaster.params.Points.threshold = 0.2

    
    mainShape = object_init
    mouse = mouse_init
    camera = camera_init

    // particles do not move at the beginning
    verticesArr = mainShape.geometry.attributes.position.array as Float32Array
    verticesArr.forEach((x, i: number) => { particlesMove[i] = 0 })

    // store geometry to restore later
    urGeo = structuredClone(verticesArr)

    // make a clone - proper deep clones don't exist in three.js so we must actually create a new object
    clone = new THREE.Points(geometry, material)
  }

  // publicApi function 2: randomize geometry on hover with mouse
  function animate () {
    mainShape.scale.x = 1
    mainShape.scale.y = 1
    mainShape.scale.z = 1

    _crotate(mainShape, clone)
    urGeo = structuredClone(clone.geometry.attributes.position.array) as Float32Array

    _update_raycast()
    random_cloud()

    if (chaos_countdown > 0) {
      chaos_countdown -= 1
    } else {
      _order_cloud()
    }

    mainShape.geometry.attributes.position.needsUpdate = true
  }

  // publicApi function 3: randomize geometry (to be called on custom events)
  function random_cloud () {
    const cloud_size = 3

    verticesArr.forEach((x, i) => {
      verticesArr[i] += particlesMove[i]
      if (verticesArr[i] > cloud_size || verticesArr[i] < -cloud_size) {
        particlesMove[i] *= -1
      }
    })
  }

  const publicAPI = {
    init,
    animate,
    random_cloud
  }
  return publicAPI


  // private helper methods

  // detect when mouse hovers object
  function _update_raycast () {
    raycaster.setFromCamera(mouse, camera)
    // we intersect with the stable clone because the chaotic object might ocupy all of the screen, always intersecting
    // thus you can only send particles flying that are at their original position 
    const intersectionsArr: Array<THREE.Intersection> = raycaster.intersectObject(clone)
    const intersection: null | THREE.Intersection = (intersectionsArr.length) > 0 ? intersectionsArr[0] : null

    if (intersectionsArr.length > 0) {
      _catch_vertices(intersection.point)
    }
  }

  // when mouse hovers object, determine which points are affected and send them flying
  function _catch_vertices (point: THREE.Vector3) {
    // the radius of the mouse to affect points with chaos => bigger radius = bigger chaos
    const chaos_range: number = 0.4

    // iterate over vertices; jumps of 3 (for x, y, z)
    for (let i: number = 0; i < verticesArr.length; i = i + 3) {
      var Vertice = { x: verticesArr[i], y: verticesArr[i + 1], z: verticesArr[i + 2] }

      // find out which Vertices are touched by mouse/raycast by comparing coordinates
      var CoordinatesOverlap: number = 0
      const xyz = ['x', 'y', 'z']
      xyz.forEach((axis: string) => {
        if (Vertice[axis] <= point[axis] + chaos_range && Vertice[axis] >= point[axis] - chaos_range) {
          CoordinatesOverlap += 1
        }
        // 3 Coordinates overlap means the mouse/raycast is sufficiently near this vertice point
        if (CoordinatesOverlap === 3) {
          // reset the countdown & send this Vertice into a random direction
          chaos_countdown = 200
          particlesMove[i] = random() * particleSpeed
          particlesMove[i + 1] = random() * particleSpeed
          particlesMove[i + 2] = random() * particleSpeed
        }
      })
    }
  }

  // when chaos countdown expires, restore original geometry from clone
  function _order_cloud () {
    verticesArr.forEach((x, i: number) => {
      // send particles towards their original geometry coords
      if (verticesArr[i] > urGeo[i]) { particlesMove[i] = -particleSpeed }
      if (verticesArr[i] < urGeo[i]) { particlesMove[i] = particleSpeed }

      // once they are near enough, set the coords - otherwise they might always jump around their destination
      if (verticesArr[i] <= urGeo[i] + 0.01 && verticesArr[i] >= urGeo[i] - 0.01) {
        particlesMove[i] = 0
        verticesArr[i] = urGeo[i]
      }
    })
  }
}

export { destabilize }


// helper functions
function random () {
  return Math.random() - 0.5
}

function _crotate (object: THREE.Points, clone: THREE.Points) {
  _rotate_geometry(object)
  _rotate_geometry(clone)
}

function _rotate_geometry (object: THREE.Points) {
  _update_matrix(object)
  _rotate(object)
}

function _rotate (object: THREE.Points, rotation = 0.0005) {
  object.rotation.x = rotation
  object.rotation.y = rotation
  object.rotation.z = rotation
}

function _update_matrix (object: THREE.Points) {
  object.updateMatrix()
  object.geometry.applyMatrix4(object.matrix)
  object.matrix.identity()
}
