

function boot_anim (
  querySelectorSearch: string = 'section.scrolled :is(h1, h2, h3, h4, p, span, li)', 
  delayMilliseconds: number = 60,
  insertTextBefore: string = null,
  ) {
  const terminal_lines: NodeListOf<HTMLElement> = document.querySelectorAll(querySelectorSearch)

  // hide all lines first
  terminal_lines.forEach(line => line.style.visibility = "hidden")

  // then make all lines appear after each other
  terminal_lines.forEach(function (terminal_line: HTMLElement, index: number) {
    setTimeout(function () {
      // insert text before if applicable
      if (insertTextBefore !== null) {
        const status: Text = document.createTextNode(insertTextBefore)
        terminal_line.insertBefore(status, terminal_line.firstChild)
      }

      terminal_line.style.visibility = "visible"

      // each line appears 100 milliseconds after the previous
    }, delayMilliseconds * (index + 1))
  })
}

export default boot_anim