export function initDraggables() {
  const draggables: NodeListOf<HTMLElement> = document.querySelectorAll('.draggable')
  const DragHandles: NodeListOf<HTMLElement> = document.querySelectorAll('.drag-handle')

  draggables.forEach(function (element: HTMLElement, index: number) {
    const dragHandle = DragHandles[index]
    dragElement(element, dragHandle)
  })
}

export var elementDragged: HTMLElement | null = null

function dragElement (dragElem: HTMLElement, dragHandle: HTMLElement) {
  let pos1 = 0; let pos2 = 0; let pos3 = 0; let pos4 = 0
  if (dragHandle) {
    // if present, the dragHandle is where you grab the element
    dragHandle.onmousedown = dragMouseDown
  } else {
    // otherwise, grab the Element from anywhere inside it
    dragElem.onmousedown = dragMouseDown
  }

  function dragMouseDown (e: MouseEvent) {
    e.preventDefault()
    // get the mouse cursor position at startup:
    pos3 = e.clientX
    pos4 = e.clientY
    document.onmouseup = closeDragElement
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag
  }

  function elementDrag (e: MouseEvent) {
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX
    pos2 = pos4 - e.clientY
    pos3 = e.clientX
    pos4 = e.clientY

    // set the element's new position:
    dragElem.style.top = (dragElem.offsetTop - pos2) + 'px'
    dragElem.style.left = (dragElem.offsetLeft - pos1) + 'px'

    // otherwise elements positioned relative to right will resize
    dragElem.style.right = 'auto'
    dragElem.style.bottom = 'auto'

    elementDragged = dragElem
    elementDragged.style.pointerEvents="None" // allows hovered elements BELOW the dragged item to be detected
  }

  function closeDragElement () {
    // stop moving when mouse button is released:
    document.onmouseup = null
    document.onmousemove = null
    dragElem.style.pointerEvents="auto"
    elementDragged = null
  }
}