import { initDraggables, elementDragged } from './0_draggable.js'
import { setColorTheme } from './4_themes.js';
import { initAnimation } from './2_cube.ts';

// 2. drag+drop icons into / out of folders
// => calculate position based on grid coordinates & if they are occupied ... or just drop it where you left it? but how make sure it does not land on the handle?

// only one icon can be selected
// folders can only be opened once

// 4. interactive terminals

interface DesktopItem {
    name: string,
    icon: string,
    function: Function,
    content: any
}

interface DesktopFolder extends DesktopItem {
    content: { [key: string]: DesktopItem }
}

const desktopIcons: { [key: string]: DesktopFolder } = {
    // termInfo: {
    //     name: 'info',
    //     icon: '',
    //     function: createTerminal,
    //     content: "<span>❯ function</span><h1>software engineer</h1>"
    // },

    // folderFiles: {
    //     name: 'files',
    //     icon: '',
    //     function: createFolder,
    //     path: "/files",
    //     content: {
    //         termHello: {
    //             name: 'hello',
    //             icon: '',
    //             function: createTerminal,
    //             content: "hello"
    //         }
    //     }
    // },

    folderColors: {
        name: 'colors',
        icon: '',
        function: createFolder,
        content: {
            lightMode: {
                name: "light",
                icon: '',
                function: setColorTheme,
                content: "light"
            },
            darkMode: {
                name: "dark",
                icon: '󰽧',
                function: setColorTheme,
                content: "dark"
            },
            blueMode: {
                name: "blue",
                icon: '',
                function: setColorTheme,
                content: "blue"
            },
        }
    },

    folderShapes: {
        name: 'shapes',
        icon: '󱗾',
        function: createFolder,
        content: {
            sphere: {
                name: "sphere",
                icon: "󱥔",
                function: initAnimation,
                content: "sphere"
            },
            cube: {
                name: "cube",
                icon: "󰆧",
                function: initAnimation,
                content: "cube"
            },
            pyramid: {
                name: "pyramid",
                icon: "󱥒",
                function: initAnimation,
                content: "pyramid"
            },
            donut: {
                name: "donut",
                icon: "",
                function: initAnimation,
                content: "donut"
            },
            plane: {
                name: "plane",
                icon: "󰹟",
                function: initAnimation,
                content: "plane"
            },
        }
    }
}

const desktop: HTMLElement = document.querySelector('.desktop');
var leftFolder: boolean = false
// when dropping dragged element on desktop, place element on desktop
desktop.onmouseup = (event) => {
    if (elementDragged && elementDragged.parentElement != desktop && leftFolder) {
        desktop.append(elementDragged);
        elementDragged.style.top="500px"
        elementDragged.style.left="50px"

        leftFolder = false
    }
}

createDesktopIcons(desktop, desktopIcons);
initDraggables();



function createTerminal(content: string) {
    const window = document.createElement("div")
    window.classList.add("window-div", "draggable", "desktop-window");
    createHeader(window);

    const windowContent = document.createElement("div")
    windowContent.classList.add("window-content")
    windowContent.innerHTML = content
    window.appendChild(windowContent)

    desktop.appendChild(window);
    
    initDraggables();
}

function createFolder(content: { [key: string]: DesktopItem }) {
    const folder: HTMLElement = document.createElement("div");
    folder.classList.add("folder", "draggable");
    folder.style.top = "600px";
    folder.style.left = "200px";

    // highlight folder when hovered with dragged element
    folder.onmouseenter = (event) => {
        if (elementDragged) {
            folder.style.background = "var(--accent-color)"
            leftFolder = false
        }
    };

    // un-highlight folder when hovered with dragged element
    folder.onmouseleave = (event) => {
        if (elementDragged) {
            folder.style.background = "var(--bg-color)"
            leftFolder = true
        }

    };

    // when dropping dragged element, unhighlight folder & place element in folder
    folder.onmouseup = (event) => {
        if (elementDragged && elementDragged.parentElement != folder) {
            folder.append(elementDragged);
            elementDragged.style.top="0px"
            elementDragged.style.left="0"
            folder.style.background = "var(--bg-color)"
        }
        
    }

    //folder.setAttribute("dropzone", true)

    createHeader(folder);
    desktop.appendChild(folder);
    createDesktopIcons(folder, content, 30);

    initDraggables();
}


function createHeader(element: HTMLElement) {
    const header = document.createElement("div");
    header.classList.add("window-div-header", "drag-handle");
    header.appendChild(createCloseButton(element));
    element.appendChild(header)
}

function createCloseButton(element: HTMLElement) {
    const button = document.createElement("button")
    button.addEventListener('click', function() { element.remove() })
    button.classList.add("close-button")
    return button
}


function createDesktopIcons(
    container: HTMLElement, 
    source: { [key: string]: DesktopItem }, 
    top: number = 0) {

    var index = 0
    let x = 20
    let y = top

    for (const key in source) {

        const desktopIcon = source[key]
        const icon = document.createElement("div")
        index++

        icon.classList.add("desktop-icon", "draggable", "drag-handle")
        icon.innerHTML = '<p class="icon">'+ desktopIcon.icon +'</p><p>'+ desktopIcon.name +'</p>'

        icon.style.left = x + "px"
        icon.style.top = y + "px"
        
        
        // set next x/y
        y += 60
        if ( (index) % 2 === 0 ) {
            x += 60
            y = top
        }
        
        icon.addEventListener('click', function() { 
            let currentlySelected = document.querySelector('.selected')
            currentlySelected.classList.remove('selected')
            icon.classList.add('selected')
        })

        icon.addEventListener("dblclick", (event) => {
            event.preventDefault(); 
            desktopIcon.function(desktopIcon.content)    
        });

        container.appendChild(icon)
    }
}




