
import animate_console from './console'

const cube = document.getElementById('cube')
const gradient = document.getElementById('gradient-canvas')
const main = document.getElementById('main')
const boot = document.getElementById('boot')


// while developing, you might not want to show boot screen after every page reload
const currentUrl = window.location.href;
let show_boot: boolean = true
let delay_modifier = 1
if (currentUrl.includes('#dev')) {
  show_boot = false
  delay_modifier = 0
}


// hide elements for bootscreen
if (show_boot) {
  hideSite()
  boot.style.opacity = "1"
  animate_console('#boot :is(h1, h2, h3, h4, p)', 100, '[    OK    ]   ')
}
// render the site
renderSite()



function hideSite () {
  document.body.removeChild(cube)
  gradient.style.opacity = "0"
}

function renderSite () {
  setTimeout(function () {
  }, 1000*delay_modifier)

  setTimeout(function () {
    document.body.removeChild(boot)
  }, 2000*delay_modifier)

  setTimeout(function () {
    gradient.style.opacity = "1"
    document.body.appendChild(cube)
  }, 3000*delay_modifier)

  setTimeout(function () {
    document.body.removeChild(main)
    main.style.opacity = "1"
    document.body.appendChild(main)
  }, 4000*delay_modifier)
}
